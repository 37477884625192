<template>
  <div class="tabs-layout">
    <dynamic-tabs :activeName.sync="activeName" :tabs="tabs"></dynamic-tabs>
  </div>
</template>

<script>
import DynamicTabs from '../../../components/DynamicTabs.vue';

export default {
  name: 'GiveUpRealRightIndex',
  components: {
    DynamicTabs,
  },
  data() {
    return {
      activeName: 'give-up-redeem',
      tabs: [
        {
          label: '放弃赎回待签约',
          component: 'give-up-redeem',
        },
        {
          label: '已签约放弃物权',
          component: 'signed-real-right',
        },
        {
          label: '未签约放弃物权',
          component: 'not-signed-real-right',
        },
      ],
    };
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss">
</style>
